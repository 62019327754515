import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Pipe({
  name: 'errorValidator'
})
export class ErrorValidatorPipe implements PipeTransform {

  private errorsObject = {
    required: () => 'Campo requerido',
    minlength: ({requiredLength, actualLength}) => `El valor mínimo son ${requiredLength} caracteres`,
    maxlength: ({requiredLength, actualLength}) => `El valor máximo son ${requiredLength} caracteres`,
    min: ({min, actual}) => `El valor mínimo es ${min}`,
    max: ({max, actual}) => `El valor máximo es ${max}`,
  };

  transform(errorKeys: ValidationErrors): string {

    const validatorError = Object.keys(errorKeys)[0];

    if (this.errorsObject[validatorError]) {      
      return this.errorsObject[validatorError](errorKeys[validatorError]);
    }
    
  }

}
