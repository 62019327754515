import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { SidenavService } from './services/sidenav.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {

  @ViewChild('sidenav', {static: false}) Sidenav: MatDrawer;
  buttons: Array<any> = [
    { path: '/', name: 'Inicio', link: '/'},
    { path: '/productos', name: 'Productos', link: '/products'},
    { path: '/cart', name: 'Carrito', link: '/cart'},
  ];

  constructor(private _sidenavService: SidenavService) {} 

  ngOnInit(): void {
  }
  
  ngAfterViewInit() {
    this._sidenavService.$openClose.subscribe((resp: boolean) => {
      return this.Sidenav.toggle();
    })
  }
}
