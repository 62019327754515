import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { MenuSign } from "src/app/models/enum.model";
import { Product } from "src/app/models/products.model";
import { CartService } from "src/app/services/cart.service";
import { SidenavService } from "src/app/services/sidenav.service";
import { SigninComponent } from "../../@modals/signin/signin.component";
import { SignupComponent } from "../../@modals/signup/signup.component";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
  @ViewChild("cart", { static: false }) btnCart: ElementRef<HTMLImageElement>;

  constructor(
    public dialog: MatDialog,
    private _sidenavService: SidenavService,
    public _cartService: CartService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  openCategory(idTipo: number, idCategory: number) {
    this.router.navigate(["/products", idTipo, idCategory]);
  }

  openModal(type: "signIn" | "signUp") {
    if (type === MenuSign.registrarse) {
      const signupDialog = this.dialog.open(SignupComponent, {
        maxWidth: "600px",
      });

      signupDialog.afterClosed().subscribe((result) => {
        console.log(`Dialog result: ${result}`);
      });
    }

    if (type === MenuSign.iniciarSesion) {
      const signupDialog = this.dialog.open(SigninComponent, {
        maxWidth: "460px",
      });

      signupDialog.afterClosed().subscribe((result) => {
        console.log(`Dialog result: ${result}`);
      });
    }
  }

  deleteItem(item: Product) {
    this._cartService.removeFromCart(item);
  }

  toggleMenu() {
    this._sidenavService.openClose.next(true);
  }
}
