import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { StockSelectPipe } from "./pipes/stock-select.pipe";
import { ErrorValidatorPipe } from "./pipes/error-validator.pipe";
import { SafeUrlPipe } from "./pipes/safe-url.pipe";
import { NavbarComponent } from "./@components/navbar/navbar.component";
import { MaterialModule } from "../material.module";
import { RouterModule } from "@angular/router";
import { FooterComponent } from "./@components/footer/footer.component";

const pipes = [StockSelectPipe, ErrorValidatorPipe, SafeUrlPipe];
const components = [NavbarComponent, FooterComponent];
@NgModule({
  declarations: [...pipes, ...components],
  imports: [CommonModule, RouterModule, MaterialModule],
  exports: [...pipes, ...components],
})
export class SharedModule {}
